import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeviceLocationStatus } from 'store/devices-store/devices.model';

/**
 * info modals
 */
@Component({
  selector: 'pro-android-location-info-modal',
  styles: [
    `
      ion-content {
        --background: transparent;
      }

      ion-list {
        overflow: auto;
        margin: 0;
      }

      .buttons {
        padding-left: 16px;
        margin-bottom: 24px;
      }
    `,
  ],
  template: `
    <ion-content class="padding-0">
      <ion-list>
        <div class="ion-padding">
          <ion-text color="secondary">
            <h3 i18n>Erlaubnis der Standortzulassung</h3>
          </ion-text>
          <p i18n>
            Um Deine Messgeräte verbinden zu können wirst Du nach einer Erlaubnis zur
            Standortabfrage Deines Handys gefragt werden. Mit Deiner Zustimmung kann die ProHerz App
            über GPS-Signal die Position von Handy und Messgerät erfassen, auch wenn die App inaktiv
            ist. Das ist nötig für die Nutzung von Bluetooth.
          </p>
          <p i18n>
            ProHerz verwendet die Ortung ausschließlich um via Bluetooth mit Deinen Messgeräten zu
            kommunizieren. Es werden keine Standortdaten an unsere Server übertragen oder
            anderweitig erfasst und verarbeitet.
          </p>
          <p i18n>Du kannst Deine Zustimmung jederzeit in den Handyeinstellungen widerrufen.</p>
        </div>

        <div class="buttons">
          <ion-button expand="block" (click)="dismissInfoModal()" i18n="@@ok">Ok</ion-button>
        </div>
      </ion-list>
    </ion-content>
  `,
})
export class AndroidLocationInfoModalComponent {
  constructor(private modalController: ModalController) {}

  dismissInfoModal() {
    this.modalController.dismiss();
  }
}

@Component({
  selector: 'pro-android-location-settings-info-modal',
  styles: [
    `
      ion-content {
        --background: transparent;
      }

      ion-list {
        overflow: auto;
        margin: 0;
      }

      .buttons {
        padding-left: 16px;
        margin-bottom: 24px;
      }
    `,
  ],
  template: `
    <ion-content class="padding-0">
      <ion-list>
        <div class="ion-padding">
          <ion-text color="secondary">
            <h3 i18n>Fehlende Berechtigung zum Standortzugriff</h3>
          </ion-text>
          <p i18n>Du hast die Berechtigung zum Standortzugriff dauerhaft abgelehnt.</p>
          <p i18n>
            Diese Berechtigung ist nötig, um Deine Messwerte via Bluetooth zu übertragen. Bitte
            erlaube der App ProHerz den Standortzugriff in den Systemeinstellungen Deines
            Mobilgerätes.
          </p>
        </div>
        <div class="buttons">
          <ion-button expand="block" (click)="dismissInfoModal()" i18n="@@ok">Ok</ion-button>
        </div>
      </ion-list>
    </ion-content>
  `,
})
export class AndroidLocationSettingsInfoModalComponent {
  constructor(private modalController: ModalController) {}

  dismissInfoModal() {
    this.modalController.dismiss();
  }
}

@Component({
  selector: 'pro-android-location-info',
  templateUrl: './android-location-info.component.html',
  styleUrls: ['./android-location-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AndroidLocationInfoComponent {
  @Input() showLocationInfo = false;

  @Input() iconOnly = false; // displays only the icon. does show modal only on click

  @Input() set permissionStatus(status: DeviceLocationStatus) {
    if (status === 'UNKNOWN') {
      this.presentLocationInfo();
    }

    if (status === 'DENIED_FINALLY') {
      this.presentSettingsInfo();
    }
  }

  @Output() requestPermission = new EventEmitter();

  constructor(private modalController: ModalController) {}

  openLocationSettings() {
    this.iconOnly ? this.presentLocationInfo() : this.requestPermissions();
  }

  requestPermissions() {
    this.requestPermission.emit();
  }

  async presentLocationInfo() {
    const modal = await this.modalController.create({
      component: AndroidLocationInfoModalComponent,
      cssClass: 'info-modal',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then(() => {
      this.requestPermissions();
    });

    await modal.present();
  }

  async presentSettingsInfo() {
    const modal = await this.modalController.create({
      component: AndroidLocationSettingsInfoModalComponent,
      cssClass: 'info-modal',
      backdropDismiss: false,
    });

    await modal.present();
  }
}
