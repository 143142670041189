<div class="scroll">
  <div class="content">
    <div class="ion-padding-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-status-help.svg"
        color="secondary"
        class="head-icon"
      ></ion-icon>
    </div>

    <h5 i18n>Änderungen der Medikation</h5>
    <p i18n>
      Eine Neueingabe oder Änderung der Medikation gilt erst ab dem folgenden Tag. Bis dahin stehen
      im Medikationsplan noch die alten Werte und diese gelten auch für die Erinnerungen.
    </p>

    <h5 i18n>Kommentare zur Nicht-Einnahme</h5>
    <p i18n>
      Du kannst Gründe für die Nicht-Einnahme eines Medikaments als Kommentar hinterlassen, wenn es
      zum Beispiel Probleme dabei gab. Dazu drückst Du einfach doppelt auf das Auswahlsymbol des
      Medikaments. Der Kreis füllt sich rot mit weißem Kreuz.
    </p>

    <div class="comment-explanation ion-margin-top">
      <div class="row">
        <pro-toggle-checkbox [disabled]="false"></pro-toggle-checkbox>
        <span class="arrow-to-right"></span>
        <pro-toggle-checkbox [disabled]="false" [value]="true"></pro-toggle-checkbox>
        <span class="arrow-to-right"></span>
        <pro-toggle-checkbox [disabled]="false" [value]="false"></pro-toggle-checkbox>
      </div>

      <div class="row explanations ion-padding-vertical ion-margin-bottom ion-align-items-start">
        <span class="ion-text-left" i18n>Keine Auswahl</span>
        <span class="ion-text-center" i18n>Einnahme erfolgt</span>
        <span class="ion-text-right" i18n>Einnahme nicht erfolgt<br />+ Kommentar</span>
      </div>
    </div>
  </div>
</div>
<footer>
  <ion-button class="left-top-border-radius" (click)="popoverController.dismiss()" i18n="@@ok"
    >Ok</ion-button
  >
</footer>
