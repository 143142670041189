import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pro-modal-buttons',
  templateUrl: './modal-buttons.component.html',
  styleUrls: ['./modal-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalButtonsComponent {
  @Input() disabled = true;
  @Input() saveLabel = $localize`:@@save:Speichern`;
  @Input() cancelLabel = $localize`:@@back:Zurück`;

  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  @Output() save: EventEmitter<boolean> = new EventEmitter();

  emitCancel() {
    this.cancel.emit();
  }

  emitSave() {
    this.save.emit();
  }
}
