import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerRequestAction } from '@ngrx/router-store';
import { PopoverController, ToastController } from '@ionic/angular';
import { EMPTY, throwError, of, from } from 'rxjs';
import {
  switchMap,
  mergeMap,
  debounceTime,
  map,
  tap,
  concatMap,
  delay,
  withLatestFrom,
} from 'rxjs/operators';
import * as AppActions from './app.actions';
import * as AuthActions from 'store/auth-store/auth.actions';
import * as DeviceActions from 'store/devices-store/devices.actions';
import * as DocumentActions from 'store/documents-store/document.actions';
import * as MedicationIntakesActions from 'store/medication-intakes-store/medication-intakes.actions';
import * as MeasurementActions from 'store/measurements-store/measurements.actions';
import * as MonitoringGoalActions from 'store/monitoring-goals-store/monitoring-goals.actions';
import * as ActivationActions from 'store/activations-store/activations.actions';
import { StorageFacade } from 'store/storage-store/storage.facade';
import { environment } from 'environments/environment';
import * as fromInfo from 'store/info-store/info.reducer';
import { GraceSubscriptionInfoComponent } from 'store/app-store-shared/grace-subscription-info/grace-subscription-info.component';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as dayjs from 'dayjs';

dayjs.extend(isBetween);

@Injectable()
export class AppEffects {
  constructor(
    private store: Store<fromInfo.State>,
    private actions$: Actions,
    private storageFacade: StorageFacade,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private router: Router
  ) {}

  showHttpErrorMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.showHttpErrorMessage),
      debounceTime(1000),
      switchMap((action) => {
        if (action.error.status?.toString().startsWith('5')) {
          this.presentToast($localize`Keine Verbindung zum Server möglich`);
        } else if (action.error.status === 401) {
          this.presentToast(
            $localize`Deine Sitzung wurde beendet. Bitte melde dich erneut an.`,
            ''
          );
          return of(DeviceActions.dismissAliveCor());
        } else if (
          action.error.status === 400 ||
          (action.error.status === 422 && !action.error.url.includes('activations')) ||
          !action.error.status?.toString().startsWith('4')
        ) {
          this.presentToast(
            $localize`Hoppla! Es ist ein Fehler aufgetreten. (V${environment.version})`
          );
        }
        return throwError(() => action.error);
      })
    );
  });

  showErrorMessage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppActions.showErrorMessage),
        switchMap(() => {
          this.presentToast(
            $localize`Hoppla! Es ist ein Fehler aufgetreten. (V${environment.version})`
          );
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  hideHttpErrorMessage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppActions.hideHttpErrorMessage, AuthActions.authenticate),
        tap(() => {
          // Checks if there is an open toast to close
          this.toastController.getTop().then((toastElement) => {
            if (toastElement !== undefined) {
              this.toastController.dismiss();
            }
          });
        })
      );
    },
    { dispatch: false }
  );

  routerRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerRequestAction),
      map((action) => {
        const sourceUrl = action.payload.routerState.url.split('?')[0];
        const navUrl = action.payload.event.url.split('?')[0];

        // change animation to modal when navigating from page to modal
        // and to page when navigating back from a modal to a page
        if (
          (!sourceUrl.includes('modal') && navUrl.includes('modal')) ||
          (sourceUrl.includes('modal') && !navUrl.includes('modal'))
        ) {
          return AppActions.changeRouterState({
            animation: 'modal',
            previousRouteSnapshot: action.payload.routerState,
          });
        } else {
          return AppActions.changeRouterState({
            animation: 'page',
            previousRouteSnapshot: action.payload.routerState,
          });
        }
      })
    );
  });

  resetCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AppActions.resetCache,
        AuthActions.signOutSuccess,
        AuthActions.deleteUserSuccess,
        AuthActions.clearState
      ),
      switchMap(() =>
        from(this.storageFacade.set('cache', null)).pipe(map(() => AppActions.resetCacheSuccess()))
      )
    );
  });

  checkCacheEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DocumentActions.loadDocumentsFailure,
        MedicationIntakesActions.loadMedicationIntakesFailure,
        MeasurementActions.loadMeasurementsFailure,
        MonitoringGoalActions.loadMonitoringGoalsFailure
      ),
      mergeMap((action) =>
        from(this.storageFacade.get('cache')).pipe(
          map((cache: Map<string, HttpRequest<unknown>>) => {
            if (cache && cache.delete(action.error.url)) {
              return AppActions.deleteCacheEntry({ url: action.error.url, cache });
            }
            return AppActions.deleteCacheEntry({ url: null });
          })
        )
      )
    );
  });

  deleteCacheEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.deleteCacheEntry),
      mergeMap((action) =>
        this.getDeleteCacheEntryObservable(action.cache).pipe(
          map(() => {
            return AppActions.deleteCacheEntrySuccess({ url: action.url });
          })
        )
      )
    );
  });

  routerNavigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppActions.navigate),
        concatMap((action) => of(action).pipe(delay(action.delay))),
        concatMap((action) => this.router.navigate(action.commands))
      );
    },
    { dispatch: false }
  );

  redirect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivationActions.loadActivationsSuccess),
      withLatestFrom(this.store.pipe(select(fromInfo.selectAcceptedDelayedDeletion))),
      map(([action, acceptedDelayedDeletion]) => {
        const hasEnqueuedSubscriptions =
          action.activations.find((activation) => activation.status === 'enqueued') !== undefined;
        const currentSubscription = action.activations.find(
          (activation) => activation.status === 'active'
        );
        const showModalDate = localStorage.getItem('pro_subscriptionReminderDate');

        // do not redirect if there is an existing enqueued subscription
        if (hasEnqueuedSubscriptions) {
          return AppActions.suppressRedirect();
        }

        // show grace subscription popup when 30 days or less are remaining
        if (
          ['grace'].includes(currentSubscription?.category) &&
          dayjs().isBetween(
            dayjs(currentSubscription?.end_date).subtract(30, 'days'),
            dayjs(currentSubscription?.end_date),
            'day',
            '[]'
          ) &&
          (!showModalDate ||
            !dayjs(showModalDate).isBetween(
              dayjs(currentSubscription?.end_date).subtract(30, 'days'),
              dayjs(currentSubscription?.end_date),
              'day',
              '[]'
            ))
        ) {
          localStorage.setItem('pro_subscriptionReminderDate', dayjs().format('YYYY-MM-DD'));
          return AppActions.showGraceSubscriptionPopup();
        }

        // redirect to subscription-reminder when 3 days are remaing
        if (
          ['test', 'shop', 'shop_test', 'diga', 'study'].includes(currentSubscription?.category) &&
          dayjs(currentSubscription?.end_date).subtract(3, 'days').format('YYYY-MM-DD') ===
            dayjs().format('YYYY-MM-DD') &&
          dayjs(currentSubscription?.end_date).subtract(3, 'days').format('YYYY-MM-DD') !==
            showModalDate
        ) {
          return this.setItemAndDispatchNavigate();
        }

        // redirect to subscription-reminder when a 3 days or less are remaing and acceptedDelayedDeletion is false
        if (
          ['shop', 'shop_test', 'diga', 'study'].includes(currentSubscription?.category) &&
          dayjs().isBetween(
            dayjs(currentSubscription?.end_date).subtract(3, 'days'),
            dayjs(currentSubscription?.end_date),
            'day'
          ) &&
          (!showModalDate ||
            !dayjs(showModalDate).isBetween(
              dayjs(currentSubscription?.end_date).subtract(3, 'days'),
              dayjs(currentSubscription?.end_date),
              'day',
              '[]'
            )) &&
          !acceptedDelayedDeletion
        ) {
          return this.setItemAndDispatchNavigate();
        }

        // redirect to subscription-reminder when 1 day is remaing
        if (
          currentSubscription?.category === 'test' &&
          dayjs(currentSubscription?.end_date).subtract(1, 'days').format('YYYY-MM-DD') ===
            dayjs().format('YYYY-MM-DD') &&
          dayjs(currentSubscription?.end_date).subtract(1, 'days').format('YYYY-MM-DD') !==
            showModalDate
        ) {
          return this.setItemAndDispatchNavigate();
        }

        return AppActions.suppressRedirect();
      })
    );
  });

  showGraceSubscriptionPopup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppActions.showGraceSubscriptionPopup),
        switchMap(() => {
          this.presentGraceSubscriptionPopover();
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  private getDeleteCacheEntryObservable(cache?: Map<string, HttpRequest<unknown>>) {
    return cache !== undefined ? from(this.storageFacade.set('cache', cache)) : of({});
  }

  private setItemAndDispatchNavigate() {
    localStorage.setItem('pro_subscriptionReminderDate', dayjs().format('YYYY-MM-DD'));

    // navigate to modal with delay to finish initial navigation first
    return AppActions.navigate({
      commands: ['app/modal/subscriptions-reminder'],
      delay: 1000,
    });
  }

  private async presentGraceSubscriptionPopover() {
    const popover = await this.popoverController.create({
      component: GraceSubscriptionInfoComponent,
      cssClass: ['pro-popover'],
    });
    return await popover.present();
  }

  private async presentToast(
    header: string,
    message = $localize`Der Support kümmert sich bereits um das Problem. Bitte probiere es zu einem späteren Zeitpunkt noch einmal.`
  ) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      color: 'warning',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  ngrxOnInitEffects(): any {
    return AppActions.resetCache();
  }
}
