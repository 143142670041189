<ion-label position="stacked" class="large" *ngIf="result">
  <span *ngIf="result.type === 'blood_pressure'">
    <span *ngIf="result | hasOwnProperty : 'user'">
      <ng-container i18n>Nutzer </ng-container>{{ $any(result).user }}: </span
    >{{ $any(result).systolic }}/{{ $any(result).diastolic }}
    mmHg
  </span>

  <span *ngIf="result.type === 'heart_rate'">{{ $any(result).value }} bpm</span>

  <span *ngIf="result.type === 'oxygen_saturation'">{{ $any(result).value }} %</span>

  <span *ngIf="result.type === 'weight'">{{ $any(result).value | number : '1.1-1' }} kg</span>

  <span *ngIf="result.type === 'temperature'">{{ $any(result).value | number : '1.1-1' }} °C</span>

  <span *ngIf="result.type === 'po60'">{{ $any(result).oxygenSaturation.value }} %</span>
  <span *ngIf="result.type === 'po60'"><br />{{ $any(result).heartRate.value }} bpm</span>
</ion-label>
<ion-label position="stacked" *ngIf="result">
  {{ result.device_time | date : 'shortTime' }} <ng-container i18n>Uhr</ng-container>
</ion-label>
