<form *ngIf="phoneForm" [formGroup]="phoneForm">
  <ion-item-sliding
    formArrayName="phone_numbers"
    lines="full"
    *ngFor="let numberControls of phoneForm.get('phone_numbers')?.controls; let i = index"
  >
    <ion-item lines="full">
      <ion-label position="stacked">
        <ng-container i18n>Telefonnummer</ng-container>&nbsp;
        {{ phoneNumbersArr[i].type | formOptionsTranslate : 'phone_number' : 'types' | async }}
        <span *ngIf="numberRequired === false" class="optional" i18n="@@optionalField"
          >optionales Feld</span
        >
      </ion-label>

      <ion-input
        [formControlName]="i"
        type="text"
        inputmode="tel"
        (ionBlur)="formatInputs()"
      ></ion-input>
    </ion-item>

    <ion-item-options side="end">
      <ion-item-option color="warning" (click)="removePhoneNumber(i)">
        <ion-icon name="trash-outline" size="large"></ion-icon>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>

  <ion-item lines="full" *ngIf="isInvalid === true">
    <span class="form-error no-phone-number-msg" i18n
      >Bitte mindestens eine Telefonnummer angeben</span
    >
  </ion-item>

  <ion-item lines="full">
    <div class="add-phone-number body-text" id="add_number">
      <ion-icon name="add" color="primary" aria-hidden="true"></ion-icon>
      <ng-container i18n>Weitere Telefonnummern hinzufügen</ng-container>
    </div>

    <ion-select
      interface="action-sheet"
      cancelText="Abbrechen"
      (ionChange)="addPhoneNumber($event)"
      class="invisible"
      [interfaceOptions]="{
        cssClass: 'no-emphasize cy-phone-type-options'
      }"
      #phoneTypeSelect
      data-cy="phone-type-select"
      aria-labelledby="add_number"
      i18n-cancelText="@@cancel"
    >
      <ion-select-option *ngFor="let p_type of phoneTypes$ | async" [value]="p_type.id">{{
        p_type.name
      }}</ion-select-option>
    </ion-select>
  </ion-item>
</form>
