<div
  class="backdrop top"
  [@backdropAnimation]="{ value: backdropVisibility, params: { delay: backdropAnimationDelay } }"
  (click)="backdropClicked()"
></div>
<div
  class="backdrop bottom"
  [@backdropAnimation]="{ value: backdropVisibility, params: { delay: backdropAnimationDelay } }"
  (click)="backdropClicked()"
></div>

<!-- info trigger -->
<div
  class="tutorial-trigger pointer"
  [ngClass]="{ hidden: tutorialLevel !== null && tutorialLevel !== tutorialLevels.cancelTutorial }"
  (click)="setTutorialLevel(tutorialLevels.intro)"
  [@fadeInOutAnimation]="{ value: ':enter', params: { delay: 0 } }"
>
  <ion-icon src="assets/svgs/info.svg" color="white" role="button"></ion-icon>
</div>

<!-- intro info -->
<div
  class="info intro"
  [@infoBoxAnimation]="{
    value: infoBoxesVisibilities.intro,
    params: { delay: infoboxAnimationDelay }
  }"
  (@infoBoxAnimation.done)="infoboxAnimationDone($event)"
>
  <ion-grid>
    <ion-row>
      <ion-col>
        <p i18n>
          NEU!<br />Diese Schaltfläche erleichtert Dir die Messwertübertragung.<br />Sieh Dir jetzt
          die neuen Funktionen an.
        </p>
      </ion-col>
    </ion-row>

    <ion-row class="buttons ion-justify-content-end">
      <ion-col size="auto">
        <ion-button
          class="button-round ion-margin-end"
          fill="outline"
          (click)="setTutorialLevel(tutorialLevels.cancelTutorial)"
          i18n
          >Später</ion-button
        >
      </ion-col>

      <ion-col size="auto">
        <ion-button class="button-round" (click)="setTutorialLevel(tutorialLevels.ctaInfo)" i18n
          >Ja, gerne</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<!-- CTA info -->
<div
  class="info cta-info"
  [@infoBoxAnimation]="{
    value: infoBoxesVisibilities.cta,
    params: { delay: infoboxAnimationDelay }
  }"
  (@infoBoxAnimation.done)="infoboxAnimationDone($event)"
>
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col>
        <p class="ion-text-center" i18n>Tippe auf die Schaltfläche.</p>
      </ion-col>

      <ion-col size="auto">
        <ion-icon
          name="close-outline"
          color="black"
          class="close-info"
          (click)="setTutorialLevel(tutorialLevels.cancelTutorial)"
        ></ion-icon>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<div
  *ngIf="tutorialLevel === tutorialLevels.ctaInfo"
  class="trigger-finger pointer"
  [@fadeInOutAnimation]="{ value: ':enter', params: { delay: 650 } }"
  (click)="openMeasurementTransfer()"
>
  <img src="assets/svgs/index-finger.svg" />
</div>

<!-- bluetooth info -->
<div
  class="info bt-info"
  [@infoBoxAnimation]="{
    value: infoBoxesVisibilities.bluetooth,
    params: { delay: infoboxAnimationDelay }
  }"
  (@infoBoxAnimation.done)="adjustBackdropForManualMessurementButton($event)"
>
  <ion-grid>
    <ion-row>
      <ion-col>
        <p i18n>
          Hier kannst Du die Messwertübertragung via Bluetooth veranlassen, falls die automatische
          Messwertübertragung nicht funktioniert.
        </p>
      </ion-col>

      <ion-col size="auto">
        <ion-icon
          name="close-outline"
          color="black"
          class="close-info"
          (click)="setTutorialLevel(tutorialLevels.cancelTutorial)"
        ></ion-icon>
      </ion-col>
    </ion-row>

    <ion-row class="buttons ion-justify-content-end">
      <ion-col size="auto">
        <ion-button
          class="button-round"
          (click)="setTutorialLevel(tutorialLevels.prepareManualMeasurementInfo)"
          i18n="@@continue"
          >Weiter</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<!-- manual meassurement info  -->
<div
  class="info manual-measurement-info"
  [@infoBoxAnimation]="{
    value: infoBoxesVisibilities.manual,
    params: { delay: infoboxAnimationDelay }
  }"
  (@infoBoxAnimation.done)="infoboxAnimationDone($event)"
>
  <ion-grid>
    <ion-row>
      <ion-col>
        <p i18n>
          Wenn Du verschiedene Messwerte manuell eintragen möchtest, dann wähle die Schaltfläche
          “Messwerte manuell übertragen”.
        </p>
      </ion-col>

      <ion-col size="auto">
        <ion-icon
          name="close-outline"
          color="black"
          class="close-info"
          (click)="setTutorialLevel(tutorialLevels.cancelTutorial)"
        ></ion-icon>
      </ion-col>
    </ion-row>

    <ion-row class="buttons ion-justify-content-end">
      <ion-col size="auto">
        <ion-button
          class="button-round"
          (click)="setTutorialLevel(tutorialLevels.finishTutorial)"
          i18n="@@everythingClear"
          >Alles klar</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<!-- open tutorial again info -->
<div
  class="info reopen-info no-arrow"
  [@infoBoxAnimation]="{
    value: infoBoxesVisibilities.reopen,
    params: { delay: infoboxAnimationDelay }
  }"
  (@infoBoxAnimation.done)="infoboxAnimationDone($event)"
>
  <ion-grid>
    <ion-row>
      <ion-col>
        <p i18n>Hier kannst Du die Erklärung erneut aufrufen.</p>
      </ion-col>

      <ion-col size="auto">
        <ion-icon
          name="close-outline"
          color="black"
          class="close-info"
          (click)="hideStartAgainInfo()"
        ></ion-icon>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
