<form *ngIf="addressForm" [formGroup]="addressForm">
  <ion-item lines="full">
    <ion-label position="stacked">
      <ng-container i18n>Straße & Hausnummer</ng-container
      ><span class="optional" i18n="@@optionalField">optionales Feld</span></ion-label
    >
    <ion-input formControlName="street" type="text" inputmode="text" proControlError></ion-input>
  </ion-item>

  <ion-item lines="full">
    <ion-label position="stacked">
      <ng-container i18n>Adresszusatz</ng-container
      ><span class="optional" i18n="@@optionalField">optionales Feld</span></ion-label
    >
    <ion-input formControlName="street_2" type="text" inputmode="text"></ion-input>
  </ion-item>

  <ion-item lines="full">
    <ion-label position="stacked">
      <ng-container i18n>Postleitzahl</ng-container
      ><span class="optional" i18n="@@optionalField">optionales Feld</span></ion-label
    >
    <ion-input
      formControlName="zip_code"
      type="text"
      inputmode="numeric"
      proControlError
    ></ion-input>
  </ion-item>

  <ion-item lines="full">
    <ion-label position="stacked">
      <ng-container i18n>Stadt</ng-container
      ><span class="optional" i18n="@@optionalField">optionales Feld</span></ion-label
    >
    <ion-input formControlName="city" type="text" inputmode="text" proControlError></ion-input>
  </ion-item>
</form>
