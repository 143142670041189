import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { Device } from '@capacitor/device';
import * as dayjs from 'dayjs';

require('dayjs/locale/de');
require('dayjs/locale/ru');
require('dayjs/locale/tr');
require('dayjs/locale/it');

@Injectable({
  providedIn: 'root',
})
class I18n {
  locale = 'de';

  async setLocale() {
    /**
     * gets the users device language
     * uses the device language if it is 'it', 'en' or 'de'
     * uses 'de' as fallback
     */
    const languageCode = (await Device.getLanguageCode()).value;
    this.locale = ['de', 'it'].includes(languageCode) ? languageCode : 'de';

    // uses the de locale for cypress E2E-tests
    if (window['Cypress' as any]) {
      this.locale = 'de';
    }
    /**
     * includes local for dates, numbers, currency, ...
     */
    const localeModule = await import(`/node_modules/@angular/common/locales/${this.locale}.mjs`);

    registerLocaleData(localeModule.default);
    dayjs.locale(this.locale);

    /**
     * loads translation file
     */
    const translationFileContent = await import(`../locale/${this.locale}.json`);
    const translations: Record<string, string> = translationFileContent?.translations;

    if (translations) {
      // loads translations for the current locale at run-time
      loadTranslations(translations);
    }
  }
}

// loads locale data at app start-up
function setLocale() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (i18n: I18n) => () => i18n.setLocale(),
    deps: [I18n],
    multi: true,
  };
}

// sets the runtime locale for the app
function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => i18n.locale,
    deps: [I18n],
  };
}

export const I18nModule = {
  setLocale: setLocale,
  setLocaleId: setLocaleId,
};
