<ng-container *ngIf="displayCta">
  <div
    *ngIf="activeLevel"
    class="backdrop"
    [ngClass]="{ transparent: tutorialActive === true }"
    (click)="toggleSelection()"
  ></div>

  <ion-item
    class="level-1"
    detail="true"
    lines="none"
    button
    (click)="openDevices()"
    data-cy="btn-open-devices-level"
    [@tileAnimation]="{ value: levelOneStatus, params: { inDelay: 300, outDelay: 0 } }"
  >
    <ion-icon
      slot="start"
      color="white"
      src="assets/svgs/icon-section-bluetooth.svg"
    ></ion-icon>

    <ion-label
      position="stacked"
      class="bold"
      i18n
    >Messwerte via Bluetooth</ion-label>
    <ion-label
      position="stacked"
      i18n
    >übertragen</ion-label>
  </ion-item>

  <ion-item
    class="level-1"
    detail="true"
    lines="none"
    (click)="navigateToManualMeasurementForm()"
    [@tileAnimation]="{ value: levelOneStatus, params: { inDelay: 0, outDelay: 300 } }"
    data-cy="btn-open-manual-measurement-input"
  >
    <ion-icon
      slot="start"
      color="white"
      src="assets/svgs/manual.svg"
    ></ion-icon>
    <ion-label
      position="stacked"
      class="bold"
      i18n
    >Messwerte manuell</ion-label>
    <ion-label
      position="stacked"
      i18n
    >übertragen</ion-label>
  </ion-item>

  <ng-container *ngIf="devicesInfo$ | async as devices">
    <ion-item
      *ngFor="let device of devices; let i = index"
      detail="true"
      lines="none"
      class="level-2"
      [routerLink]="[device.routerLink]"
      [queryParams]="device.queryParams"
      (click)="toggleSelection()"
      [@tileAnimation]="{
        value: levelTwoStatus,
        params: { inDelay: (3 - i) * 300, outDelay: i * 300 }
      }"
      [attr.data-cy]="'manual-measurement-trigger-' + device.name"
    >
      <pro-device-image
        [thumbnail]="true"
        slot="start"
        [device]="device.name"
      ></pro-device-image>
      <ion-label>{{ device.type | deviceTypeTranslation }}</ion-label>
    </ion-item>
  </ng-container>

  <ion-button
    class="cta button-round"
    data-cy="manual-measurement-cta"
    (click)="toggleSelection()"
    [@ctaAnimation]
    (@ctaAnimation.done)="initTutorial($event)"
    #manualMeasurementCta
  >
    <ion-icon
      slot="start"
      src="assets/svgs/arrow_left_right.svg"
    ></ion-icon>
    <ng-container i18n>Messwerte übertragen</ng-container>
    <div
      class="close-cta"
      slot="end"
      *ngIf="activeLevel"
      [@closeBtnAnimation]
    >
      <ion-icon name="close"></ion-icon>
    </div>
  </ion-button>

  <pro-measurement-transfer-tutorial
    *ngIf="manualMeasurementCta && tutorialVisible === true"
    [tutorialLevel]="tutorialLevel"
    [autoplay]="tutorialAutoplay"
    (tutorialLevelChanged)="setTutorialLevel($event)"
    (measurementTransferClicked)="toggleSelection()"
    [ngClass]="{ 'tiles-open': activeLevel }"
  ></pro-measurement-transfer-tutorial>
</ng-container>