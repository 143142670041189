import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonDatetime } from '@ionic/angular';

@Component({
  selector: 'pro-date-picker-item-control',
  templateUrl: './date-picker-item-control.component.html',
  styleUrls: ['./date-picker-item-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerItemControlComponent,
    },
  ],
})
export class DatePickerItemControlComponent implements ControlValueAccessor, OnInit {
  @ViewChild('datetime') datetime: IonDatetime;
  @Input() formControlName: string;
  @Input() name: string;
  @Input() lines: 'full' | 'inset' | 'none' | undefined = undefined;
  @Input() min: string | undefined = undefined;
  @Input() max: string | undefined = undefined;
  @Input() value: string | undefined = undefined;
  @Input() yearValues: number[];
  @Input() doneText = $localize`Übernehmen`;
  @Input() cancelText = $localize`:@@cancel:Abbrechen`;
  @Output() ionCancel: EventEmitter<CustomEvent<void>> = new EventEmitter();
  date: string | string[];
  touched = false;
  disabled = false;
  trigger: string;

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  ngOnInit() {
    this.trigger = this.formControlName ? this.formControlName : this.name ? this.name : 'trigger';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (date: string | string[]) => {};

  onTouched = () => {};

  writeValue(date: string) {
    if (date) {
      this.date = date;
    } else {
      // set value as date when date param is undefined
      this.date = this.value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  async setDisabledState(disabled: boolean) {
    this.disabled = disabled;

    if (disabled) {
      this.value ? await this.datetime?.reset(this.value) : await this.datetime?.reset();
    }
  }

  select(event: CustomEvent<{ value: string | string[] }>) {
    this.date = this.datetime.value;
    if (this.touched) {
      this.updateControl(event.detail.value);
    }
  }

  async confirm() {
    if (!this.touched) {
      this.updateControl(this.datetime.value);
    }
    await this.datetime.confirm(true);
  }

  async cancel() {
    if (!this.touched) {
      this.value ? await this.datetime.reset(this.value) : await this.datetime.reset();
    } else {
      typeof this.date === 'string'
        ? await this.datetime.reset(this.date)
        : await this.datetime.reset();
    }
    await this.datetime.cancel(true);
  }

  private updateControl(date: string | string[]) {
    if (!this.disabled) {
      // update control value
      this.onChange(date);
    }
    this.markAsTouched();
  }

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
