<pdf-viewer
  *ngIf="pdfSrc && !pdfError"
  [src]="pdfSrc"
  [original-size]="true"
  [fit-to-page]="true"
  [zoom]="zoom"
  [render-text]="true"
  [render-text-mode]="1"
  [autoresize]="true"
  [show-borders]="true"
  (error)="onError($event)"
  (pages-initialized)="pdfInitialized()"
></pdf-viewer>

<span *ngIf="pdfError" i18n="@@anErrorHasOccurred">Ein Fehler ist aufgetreten</span>
