<ion-header mode="ios">
  <ion-toolbar>
    <ion-back-button
      slot="start"
      mode="ios"
      defaultHref=""
      role="button"
      aria-label="Zurück"
      i18n-aria-label="@@back"
    ></ion-back-button>
    <ion-title mode="md" i18n="@@surveys">Fragebögen</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <p
    data-test-class="reg-content"
    *ngIf="(activationCategory$ | async) !== 'telemedicine_center'; else elseBlock"
    i18n
  >
    In regelmäßigen Abständen bekommst Du von uns Fragebögen zu Deinem allgemeinen Befinden. Das ist
    wichtig, da auch Deine Stimmungslage ein wichtiger Faktor ist, mit dem frühzeitig Veränderungen
    festgestellt werden können.
  </p>
  <ng-template #elseBlock>
    <p data-test-class="tmc-content" i18n>
      Zusätzlich zur täglichen Abfrage Deines subjektiven Gesundheitszustandes im Rahmen des
      telemedizinischen Monitorings erhältst Du in regelmäßigen Abständen Fragebögen zur Erfassung
      Deines Allgemeinbefindens.
    </p>
    <p data-test-class="tmc-content" i18n>
      Dadurch können wir uns einen umfassenderen Eindruck von Deiner Gesundheitslage machen.
    </p>
  </ng-template>

  <ion-list *ngIf="surveys$ | async as surveys">
    <ion-item
      *ngFor="let survey of surveys; last as isLast"
      [lines]="isLast === true ? 'none' : 'full'"
      detail
      button
      (click)="selectSurvey(survey.survey_id)"
      class="cy-{{ survey.survey_id }}"
    >
      <ion-label>
        <div
          *ngIf="
            survey.survey_id === selectedSurveyId &&
              (surveyFacade.loading$ | async) &&
              !surveyFacade.getSurvey(survey.id);
            else elseBlock
          "
          class="spinner"
        >
          <ion-spinner color="primary" name="dots"></ion-spinner>
        </div>

        <ng-template #elseBlock>
          <div>{{ survey.survey_title }}</div>
        </ng-template>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
