<div class="scroll">
  <div class="content">
    <h5 i18n>Du nutzt jetzt ein 30-tägiges Übergangsabo</h5>
    <p i18n>
      Um einen neuen Freischalt-/Aktivierungscode zu erhalten, hast Du nun weitere 30 Tage Zeit.
      Bitte gib Deinen neuen Code direkt, nachdem Du diesen erhalten hast, in der ProHerz-App ein.
    </p>
    <p i18n>Wenn Du dabei Unterstützung benötigst, kontaktiere gerne unseren Support unter:</p>
    <p i18n>
      Tel:
      <a [href]="'tel:' + supportPhone" [attr.aria-label]="supportPhoneAriaLabel" i18n-aria-label>
        {{ supportPhone }}</a
      >
    </p>
    <p i18n>
      E-Mail:
      <a
        [href]="'mailto:' + supportEmailAddress"
        [attr.aria-label]="supportEmailAriaLabel"
        i18n-aria-label
      >
        {{ supportEmailAddress }}</a
      >
    </p>
  </div>
</div>
<footer>
  <ion-button
    class="left-top-border-radius"
    expand="block"
    (click)="popoverController.dismiss()"
    i18n="@@ok"
    >Ok</ion-button
  >
</footer>
