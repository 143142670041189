import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Device, GetLanguageCodeResult } from '@capacitor/device';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(Device.getLanguageCode()).pipe<GetLanguageCodeResult, HttpEvent<any>>(
      first(),
      switchMap((languageCode) => {
        const locale = languageCode?.value;

        // only allow 'de' and 'it' as locales
        // sets 'de' as default
        let language = ['de', 'it'].includes(locale) ? locale : 'de';

        // uses the de locale for cypress E2E-tests
        if (window['Cypress' as any]) {
          language = 'de';
        }

        const modifiedReq = request.clone({
          setParams: { language },
        });

        return next.handle(modifiedReq);
      })
    );
  }
}
