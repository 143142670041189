<ion-header mode="ios">
  <ion-toolbar>
    <ion-nav-link slot="start" router-direction="back">
      <ion-back-button
        mode="ios"
        disabled="false"
        defaultHref=""
        role="button"
        aria-label="Zurück"
        i18n-aria-label="@@back"
      ></ion-back-button>
    </ion-nav-link>
    <ion-title mode="md" *ngIf="surveyTitle">{{ surveyTitle }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="result">
  <header class="header result-header center padding-r" i18n>Dein Testergebnis:</header>
  <ng-container
    *ngIf="result.survey_id === 'kccq12' || result.survey_id === 'eq5d'; else elseBlock"
  >
    <div *ngIf="result.survey_id === 'eq5d'" class="header light small center padding-r" i18n>
      Gesundheitszustand gesamt:
    </div>
    <pro-result-icon
      class="padding-r"
      [surveyId]="result.survey_id"
      [score]="averageScore"
    ></pro-result-icon>
    <ion-list>
      <ion-item
        *ngFor="let feedback of result.feedbacks; last as isLast"
        [lines]="isLast ? 'none' : 'full'"
        class="ion-no-padding"
      >
        <pro-expansion-panel class="padding-b-0" [headerHeight]="'75px'">
          <div class="flex" pro-expansion-header>
            <ion-avatar>
              <pro-result-icon [type]="'single'" [score]="feedback.score"></pro-result-icon>
            </ion-avatar>
            <ion-label>
              {{ feedback.title }}
            </ion-label>
          </div>
          <pro-expansion-content>
            <header>{{ feedback.summary }}</header>
            <p>{{ feedback.explanation }}</p>
          </pro-expansion-content>
        </pro-expansion-panel>
      </ion-item>
    </ion-list>
  </ng-container>
  <ng-template #elseBlock>
    <ng-container *ngFor="let feedback of result.feedbacks">
      <div [class.light]="result.survey_id === 'ess'" class="header center padding-r">
        {{ feedback.summary }}
      </div>
      <pro-result-icon
        class="padding-r"
        [surveyId]="result.survey_id"
        [score]="averageScore"
      ></pro-result-icon>
      <div class="feedback body-text">
        {{ feedback.explanation }}
      </div>
    </ng-container>
  </ng-template>

  <div class="info-link">
    <ion-icon name="information-circle" color="primary"></ion-icon>

    <span i18n>
      Bitte lies unsere
      <a routerLink="/app/questionnaire/modal/information">Einteilung und Beurteilung</a>
    </span>
  </div>

  <div class="buttons">
    <ion-button
      *ngIf="result.survey_id === '2ft'"
      expand="block"
      fill="outline"
      class="helpline"
      [routerLink]="['/app/questionnaire/modal/helpline']"
    >
      <ion-icon slot="start" name="call"></ion-icon>
      <ng-container i18n> Brauchst Du ein offenes Ohr? </ng-container>
    </ion-button>

    <ion-button expand="block" [routerLink]="['']" data-cy="finish-sv-btn" i18n>Beenden</ion-button>
  </div>
  <p class="result-info small padding-r" i18n>
    Dein Testergebnis wurde unter „Dokumente“ abgelegt.
  </p>
</ion-content>
