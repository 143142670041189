<div class="scroll">
  <div class="content">
    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-status-help.svg"
        color="secondary"
        class="head-icon"
      ></ion-icon>
    </div>

    <h5 i18n>Wie aktiviere ich Bluetooth?</h5>
    <p i18n>
      Bluetooth ist eine Funktechnologie zur Datenübertragung und wird durch folgendes Zeichen
      symbolisiert.
    </p>

    <div class="ion-margin-bottom ion-text-center">
      <ion-icon
        src="assets/svgs/icon-section-bluetooth.svg"
        color="secondary"
        class="content-icon"
      ></ion-icon>
    </div>

    <p i18n>
      ProHerz nutzt Bluetooth zur Übertragung der Messwerte von den medizinischen Messgeräten zur
      App. Deshalb muss Bluetooth dazu stets in den Handy-Einstellungen aktiviert sein. Neben den
      Systemeinstellungen kann man den Bluetooth-Status auch im Kontrollzentrum einsehen und
      steuern. Zu erreichen ist das Kontrollzentrum durch einen Swipe vom oberen (rechten)
      Bildschirmrand nach unten oder vom unteren Bildschirmrand nach oben (iPhone SE/8 und älter).
    </p>
  </div>
</div>
<footer>
  <ion-button class="left-top-border-radius" (click)="popoverController.dismiss()" i18n="@@ok"
    >Ok</ion-button
  >
</footer>
