<ion-content>
  <p>
    {{ textContent }}
  </p>

  <form *ngIf="passwordForm" [formGroup]="passwordForm">
    <ion-list>
      <ion-item lines="full">
        <ion-label position="stacked" i18n>Passwort</ion-label>

        <ion-input
          [type]="hidePassword === true ? 'password' : 'text'"
          formControlName="password"
          inputmode="text"
          autocomplete="password"
          enterkeyhint="done"
          (keydown)="errorMsg = ''"
          #pwInput
          proTrimOnBlur
        ></ion-input>

        <ion-icon
          (click)="togglePassword()"
          [name]="hidePassword === false ? 'eye-off-outline' : 'eye-outline'"
          slot="end"
          data-cy="toggle-pw"
          role="button"
          aria-label="{hidePassword, select, false {Passwort ausblenden} true {Passwort anzeigen}}"
          i18n-aria-label
        ></ion-icon>
      </ion-item>
    </ion-list>
    <div class="form-error" *ngIf="errorMsg !== ''">
      {{ errorMsg }}
    </div>

    <div>
      <ion-button fill="clear" (click)="dismissModal()" class="button-cancel" i18n="@@cancel"
        >Abbrechen</ion-button
      >

      <ion-button
        (click)="checkPassword()"
        [disabled]="loading || passwordForm.valid === false"
        data-cy="send-pw-btn"
      >
        <ng-container *ngIf="!loading" i18n>Absenden</ng-container>
        <ion-spinner *ngIf="loading" name="dots"></ion-spinner>
      </ion-button>
    </div>
  </form>
</ion-content>
