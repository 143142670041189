<ion-item
  *ngIf="view"
  [lines]="lines"
  [detail]="detail"
  [attr.data-cy]="view.type + '-trigger'"
  [ngClass]="{ small: small, 'centered-labels': small }"
  role="button"
>
  <pro-icon-badge
    [type]="view.type"
    [active]="view.hasValidResult"
    [small]="small"
    slot="start"
  ></pro-icon-badge>

  <!-- regular sized -->
  <ng-container *ngIf="!small">
    <div class="label-wrapper">
      <ion-label position="stacked" class="large" data-cy="monitoring-goal-name">
        {{ view.name }}
      </ion-label>

      <!-- with valid result to show -->
      <ng-container *ngIf="view.result as result">
        <ion-label position="stacked" data-cy="monitoring-goal-value">
          <ng-container *ngIf="view.type === 'EcgGoal'">
            <ng-container i18n>gemessen</ng-container><br />
            {{ result.device_time | date : 'shortTime' }}
            <ng-container i18n>Uhr</ng-container>
          </ng-container>

          <ng-container *ngIf="view.type !== 'EcgGoal'">
            <ng-container i18n>Heute:</ng-container>
            <br />{{ view.label }}
          </ng-container>
        </ion-label>
      </ng-container>

      <!-- no valid result to show -->
      <ng-container *ngIf="!view.result">
        <ion-label position="stacked" data-cy="monitoring-goal-value">
          <ng-container *ngIf="view.type === 'EcgGoal'" i18n>Messung ausstehend</ng-container>
          <ng-container *ngIf="view.type !== 'EcgGoal'">
            <ng-container i18n>Heute:</ng-container>
            <br />{{ view.label }}
          </ng-container>
        </ion-label>
      </ng-container>
    </div>
  </ng-container>

  <!-- small sized -->
  <ng-container *ngIf="small">
    <div class="label-wrapper">
      <!-- with valid result to show -->
      <ng-container *ngIf="view.result as result">
        <ion-label position="stacked" class="large" data-cy="monitoring-goal-name">
          {{ view.name }}: {{ view.label }}
        </ion-label>

        <ion-label
          *ngIf="view.type !== 'EcgGoal'"
          position="stacked"
          data-cy="monitoring-goal-value"
        >
          {{ result.medical_state }}
        </ion-label>
      </ng-container>

      <!-- no valid result to show -->
      <ng-container *ngIf="!view.result">
        <ion-label position="stacked" class="large" data-cy="monitoring-goal-name">
          {{ view.name }}
        </ion-label>

        <ion-label position="stacked" data-cy="monitoring-goal-value" i18n
          >Keine Messung
        </ion-label>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="view.result as result">
    <ion-chip
      *ngIf="view.type === 'EcgGoal'"
      [ngClass]="view.hasValidResult ? result.state : ''"
      slot="end"
    >
      <ion-icon
        *ngIf="result.state !== 'good' || view.hasValidResult === false"
        src="assets/svgs/warning.svg"
      ></ion-icon>
      {{ result.determination | ecgDetermination }}
    </ion-chip>

    <pro-traffic-light
      *ngIf="view.type !== 'EcgGoal'"
      slot="end"
      [state]="result.state"
      [small]="small"
    ></pro-traffic-light>
  </ng-container>
</ion-item>
