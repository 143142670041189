import { Pipe, PipeTransform } from '@angular/core';

export enum Plurals {
  'ReportStationary' = 'report_stationary',
  'ReportAmbulant' = 'report_ambulant',
  'Laboratory' = 'laboratory',
  'Ecg' = 'ecg',
  'EcgResult' = 'ecg_result',
  'Imaging' = 'imaging',
  'Other' = 'other',
  'ProCarement' = 'procarement',
  'Questionnaire' = 'questionnaire',
  'RightsAndDisposals' = 'rights_and_disposals',
  'QuarterlyTmcReport' = 'quarterly_tmc_report',
}

@Pipe({
  name: 'documentCategory',
})
export class DocumentCategoryPipe implements PipeTransform {
  plurals: Record<string, string> = {
    [Plurals.ReportStationary]: $localize`Krankenhausbriefe`,
    [Plurals.ReportAmbulant]: $localize`Arztbriefe`,
    [Plurals.Laboratory]: $localize`Laborberichte`,
    [Plurals.Ecg]: $localize`EKG (Arzt)`,
    [Plurals.EcgResult]: $localize`EKG (Patient)`,
    [Plurals.Imaging]: $localize`Bildgebung`,
    [Plurals.Other]: $localize`Sonstige Dokumente`,
    [Plurals.ProCarement]: $localize`ProCarement`,
    [Plurals.Questionnaire]: $localize`:@@surveys:Fragebögen`,
    [Plurals.RightsAndDisposals]: $localize`Vollmachten & Verfügungen`,
    [Plurals.QuarterlyTmcReport]: $localize`Quartalsberichte TMZ`,
  };

  transform(v: string): string {
    return this.plurals[v] === undefined ? v : this.plurals[v];
  }
}
