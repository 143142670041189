import { Directive, HostListener, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Platform } from '@ionic/angular';
import { loadFile, isOfType } from 'app/utils';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[proLink]',
})
export class LinkDirective {
  // Due to server CORS-restrictions, redirect not working with fetch.
  // Maps to pdf-file-urls
  mappedPdfUrls: Record<string, string> = {
    ['https://procarement.com/hilfe/proherz/nutzungsvereinbarung']:
      'https://procarement.com/wp-content/uploads/Nutzungsvereinbarung-ProHerz.pdf',

    ['https://procarement.com/hilfe/proherz/gebrauchsanweisung']:
      'https://procarement.com/wp-content/uploads/Gebrauchsanweisung-ProHerz.pdf',

    ['https://procarement.com/hilfe/proherz/technischefunktionsfaehigkeit']:
      'https://procarement.com/wp-content/uploads/Gewaehrleistung_Technische_Funktionsfaehigkeit-ProHerz.pdf',
    // because there is no redirection-url available on the server for the diga-one-pager PDF,
    // use the same path to force the PDF-Download on Android-Devices
    ['https://procarement.com/wp-content/uploads/aerzte-info-diga-print.pdf']:
      'https://procarement.com/wp-content/uploads/aerzte-info-diga-print.pdf',
  };

  // Accepted in-app-routes
  inAppRoutes: Record<string, string> = {
    ['/documents']: 'app/overview/documents-categories',
    ['/medications']: 'app/medication/list',
    ['/medication_intakes']: 'app/medication/intake',
    ['/diagnoses']: 'app/diagnoses',
    // Measurements
    ['/blood_pressure']: 'app/devices/select?type=BloodPressureGoal',
    ['/heart_rate']: 'app/devices/PO60',
    ['/oxygen_saturation']: 'app/devices/PO60',
    ['/temperature']: 'app/measurement-form?type=TemperatureGoal',
    ['/weight']: 'app/devices/BF720',
    // info modal-pages
    ['/hilfe/vitalparameter']: '/app/monitoring-goal/modal/information',
    ['/hilfe/frageboegen']: '/app/questionnaire/modal/information',
  };

  redirectDelay = 3000;
  redirectTimeout: NodeJS.Timeout;
  linkToOpen = '';
  backButtonSubscription: Subscription;

  @Input('proLink') targetUrl = '';

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.preventDefault();
    this.handleClickEvent(event);
  }

  constructor(
    private platform: Platform,
    private alertController: AlertController,
    private router: Router
  ) {}

  /**
   * Invokes methods to open link or navigates if link is an in-app-route
   * @param event click-event from parent
   */
  handleClickEvent(event: Event) {
    if (this.targetUrl) {
      this.linkToOpen = this.targetUrl;
    } else {
      const target = event.target;

      if (isOfType<HTMLAnchorElement>(target, 'pathname')) {
        if (this.inAppRoutes[target.pathname]) {
          this.router.navigateByUrl(this.inAppRoutes[target.pathname]);
          return;
        } else {
          this.linkToOpen = target.href;
        }
      }
    }

    // Checks if target url starts with http.
    if (this.linkToOpen.startsWith('http') === false) {
      return;
    }

    this.showRedirectAlert();
  }

  /**
   * Displays a redirect notice for 5 seconds.
   * Invokes opening target after that time.
   */
  async showRedirectAlert() {
    if (this.platform.is('android')) {
      // registers a high priority handler to disable the android back-button while alert is opened
      this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {});
    }

    const alertMessage = $localize`Du wirst auf einen externen Inhalt umgeleitet und verlässt damit die App ProHerz.`;

    const ionAlert = await this.alertController.create({
      message: '<ion-spinner name="crescent" color="warning"></ion-spinner><br>' + alertMessage,
      cssClass: 'pro-alert spinner',
      backdropDismiss: false,
      buttons: [
        {
          text: $localize`:@@cancel:Abbrechen`,
          handler: () => {
            if (this.redirectTimeout) {
              clearTimeout(this.redirectTimeout);
              ionAlert.dismiss();
            }
          },
        },
      ],
    });

    await ionAlert.present();

    ionAlert.onWillDismiss().then(() => {
      if (this.backButtonSubscription) {
        this.backButtonSubscription.unsubscribe();
      }
    });

    this.redirectTimeout = setTimeout(() => {
      ionAlert.dismiss();
      this.openLinkTarget();
    }, this.redirectDelay);
  }

  /**
   * Invokes pdf-handling on android or opens link in browser-plugin
   */
  openLinkTarget() {
    this.platform.is('hybrid') && this.platform.is('android') && this.mappedPdfUrls[this.linkToOpen]
      ? this.openPdfOnAndroid(this.mappedPdfUrls[this.linkToOpen])
      : this.openLinkInBrowser();
  }

  /**
   * Downloads the pdf to the users device and opens the pdf in the file-opener plugin
   */
  openPdfOnAndroid(targetUrl: string) {
    // Extracts filename from url.
    const fileName = targetUrl.substring(targetUrl.lastIndexOf('/') + 1) ?? 'file.pdf';
    // Fetches pdf from server.
    fetch(targetUrl)
      .then(async (fetchResult) => {
        // Gets blob from result and creates a new file.
        const blob = await fetchResult.blob();
        const createdFile = new File([blob], 'tempFile', {
          type: 'application/pdf',
        });
        // Gets base64 string from file and stores it on the users device.
        const base64 = await loadFile(createdFile);
        const storedFile = await Filesystem.writeFile({
          path: fileName,
          data: base64,
          directory: Directory.Cache,
        });
        // Opens the stored pdf in fileOpener-plugin
        FileOpener.open({ filePath: storedFile.uri, contentType: 'application/pdf' }).catch(() =>
          this.openLinkInBrowser()
        );
      })
      .catch(() => this.openLinkInBrowser());
  }

  /**
   * Opens target url in the browser-plugin
   */
  openLinkInBrowser() {
    Browser.open({ url: this.linkToOpen });
    this.linkToOpen = '';
  }
}
