<div class="scroll">
  <div class="content">
    <div class="ion-margin-bottom ion-text-center">
      <ion-icon src="assets/svgs/icon-status-help.svg" color="success" class="head-icon"></ion-icon>
    </div>

    <h5 i18n>Datenfreigabe an Deinen Arzt</h5>
    <p i18n>
      Der Arzt, bei dem Du den Haken zur Freigabe setzt, hat mit dieser Erlaubnis Einsicht in Deine
      gesammelten Daten und kann so Deine Therapie anpassen.
    </p>

    <h5 i18n>Zuordnung durch die Arztnummer</h5>
    <p i18n>
      Für die korrekte Zuordnung Deines Arztes benötigen wir dessen lebenslange Arztnummer (kurz:
      LANR). Diese findest Du z.B. auf Verschreibungen oder Krankschreibungen, oder Du erfragst sie
      einfach direkt bei Deinem Arzt.
    </p>
  </div>
</div>
<footer>
  <ion-button
    class="left-top-border-radius"
    color="secondary"
    (click)="popoverController.dismiss()"
    i18n="@@ok"
    >Ok</ion-button
  >
</footer>
