import { Pipe, PipeTransform } from '@angular/core';

export enum Plurals {
  'tablet' = 'tablet',
  'injectable' = 'injectable',
  'capsule' = 'capsule',
  'solution' = 'solution',
  'cream' = 'cream',
  'drops' = 'drops',
  'spray' = 'spray',
}

@Pipe({
  name: 'medicationIntakeFormPlural',
})
export class MedicationIntakeFormPluralPipe implements PipeTransform {
  singulars = {
    [Plurals.tablet]: $localize`Tablette`,
    [Plurals.injectable]: $localize`Injektion`,
    [Plurals.capsule]: $localize`Kapsel`,
    [Plurals.solution]: $localize`Kapsel`,
    [Plurals.cream]: $localize`Creme`,
    [Plurals.drops]: $localize`Tropfen`,
    [Plurals.spray]: $localize`Spray`,
  };

  plurals = {
    [Plurals.tablet]: $localize`Tabletten`,
    [Plurals.injectable]: $localize`Injektionen`,
    [Plurals.capsule]: $localize`Kapseln`,
    [Plurals.solution]: $localize`Lösungen`,
    [Plurals.cream]: $localize`Creme`,
    [Plurals.drops]: $localize`Tropfen`,
    [Plurals.spray]: $localize`Sprays`,
  };

  constructor() {}

  transform(formId: string, amount: number | string): string {
    if (
      (this.singulars as Record<string, string>)[formId] === undefined ||
      (this.plurals as Record<string, string>)[formId] === undefined
    ) {
      return formId;
    }

    return +amount === 1
      ? (this.singulars as Record<string, string>)[formId]
      : (this.plurals as Record<string, string>)[formId];
  }
}
